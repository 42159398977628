import React, { useEffect, useState } from 'react';
import ConfigHandler from '../config';
import { RestService } from '../utils';

function PricebookPromoBanner() {
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    RestService.get({ url: `${ConfigHandler.getConfig().api.baseUrl}${ConfigHandler.getConfig().api.path.pricebookPromo}?` })
      .then(response => {
        response.json()
          .then((data) => ({ data: data, }))
          .then((res) => {
            if (res && res.data && res.data && Array.isArray(res.data.data) && res.data.data.length > 0) setImageUrl(res.data.data[0].description);
          });
      })
      .catch(error => {
        console.error("Errore durante il caricamento dei dati:", error);
      });
  }, []);
  return imageUrl ? (<div><img className="w-100 mb-5 rounded rounded-3" src={imageUrl} alt="Promo" /></div>) : "";
};

export default PricebookPromoBanner;
